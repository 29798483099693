import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useItemsList() {
  const itemsListTable = ref([])

  const tableColumns = [
    { text: 'Name (en)', value: 'name_en' },
    { text: 'Name (ar)', value: 'name_ar' },
    { text: 'Chef Name', value: 'chief_name' },
    { text: 'kitchen Name', value: 'kitchen_name' },
    { text: 'Category', value: 'is_preorder' },
    { text: 'Total Quantity', value: 'quantity' },
    { text: 'Price (JOD)', value: 'price' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const searchQuery = ref('')
  const planFilter = ref(null)
  const totalItemsListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })
  const itemsTotalLocal = ref([])
  const selectedRows = ref([])

  // fetch data
  const fetchItems = () => {
    options.value.q = searchQuery.value

    console.log('searchQuery', searchQuery.value)
    console.log('options', options.value)
    store
      .dispatch('app-items/fetchItems', options.value)
      .then(response => {
        const { filteredData, total, itemsTotal } = response.data.data
        itemsListTable.value = filteredData
        totalItemsListTable.value = total
        itemsTotalLocal.value = itemsTotal
        console.log('response.data.data', response.data.data)
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.error(error)
      })
  }
  const fetchSizes = () => {
    return new Promise((resolve, reject) => {
      store
        .dispatch('app-items/fetchSizes')
        .then(res => {
          resolve(res)
        })
        .catch(error => {
          reject(error)
          console.error(error)
        })
    })
  }

  watch([searchQuery, planFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  })

  return {
    searchQuery,
    itemsListTable,
    planFilter,
    tableColumns,
    totalItemsListTable,
    loading,
    options,
    itemsTotalLocal,
    selectedRows,
    fetchItems,
    fetchSizes,
  }
}
