<template>
  <!-- edit profile dialog -->
  <v-dialog v-model="isBioDialogOpen" width="1250px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="item-edit-info pa-sm-10 pa-3">
      <v-card-title class="justify-center text-h5"> Edit Item Information </v-card-title>
      <v-card-text class="text-center mt-n2"> Updating Item details will receive a privacy audit. </v-card-text>

      <v-card-text class="mt-5">
        <v-form ref="form" v-model="valid" class="multi-col-validation" @submit.prevent="onSubmit">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="itemDataLocal.name_en"
                :rules="[validators.customeRequired]"
                outlined
                dense
                label="Item Name (En)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="itemDataLocal.name_ar"
                outlined
                dense
                :rules="[validators.required]"
                label="Item Name (Ar)"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" v-if="subCategories && !isAddOns">
              <v-select
                v-model="itemDataLocal.category_id"
                :rules="[validators.required]"
                placeholder="Sub-Category"
                label="Sub-Category"
                :items="subCategories"
                item-text="name_en"
                item-value="id"
                outlined
                dense
                clearable
                hide-details
                @change="subCatChange()"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" v-if="!isAddOns && itemDataLocal.is_preorder != 1">
              <v-select
                v-model="selectedAddone"
                :items="addOns"
                item-text="name_en"
                item-value="id"
                multiple
                chips
                dense
                clearable
                persistent-hint
                outlined
                label="Select Add-ons"
                hint="You can select more than one"
              >
              </v-select>
            </v-col>
            <v-col cols="12" sm="6" v-if="!isAddOns">
              <v-select
                v-model="itemDataLocal.chief_id"
                :rules="[validators.required]"
                placeholder="Chef Name"
                label="Chef Name"
                :items="chefs"
                item-text="name"
                item-value="id"
                outlined
                dense
                clearable
                hide-details
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" v-if="!itemDataLocal.sub_items.length">
              <v-text-field
                v-model="itemDataLocal.price"
                outlined
                dense
                :disabled="itemDataLocal.sub_items.length > 0 ? true : false"
                :rules="[validators.decimal_integer_Validator, calculatePointsMessage]"
                hide-spin-buttons
                label="Price (JOD)"
                @input="calculatePoints()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" v-if="itemDataLocal.type != 2 && !itemDataLocal.sub_items.length">
              <v-text-field
                v-model="itemDataLocal.quantity"
                outlined
                dense
                :rules="[validators.integerValidator]"
                hide-spin-buttons
                label="Quantity"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" v-if="itemDataLocal.type == 2">
              <v-text-field
                v-model="itemDataLocal.remaining_hours"
                :rules="[validators.required]"
                outlined
                dense
                label="reservation Time in Hours"
                hide-details="auto"
                placeholder="5 h"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" v-if="!itemDataLocal.sub_items.length">
              <v-text-field
                v-model="itemDataLocal.points"
                outlined
                dense
                disabled
                label="Price in Points"
                hide-details="auto"
                type="number"
                readonly
              ></v-text-field>
            </v-col>

            <v-col class="px-5" cols="12" sm="6">
              <v-row class="mb-6" v-if="showFileField">
                <v-file-input
                  class="file"
                  v-model="itemDataLocal.image"
                  :rules="[validators.required, fileAccept(['jpg', 'jpeg', 'png'], itemDataLocal.image)]"
                  :prepend-icon="icons.mdiCamera"
                  accept=".jpg, .jpeg, .png"
                  label="Upload Image (.jpg, .jpeg, .png)"
                  hint="Desired dimensions: min-width: 833px; max-width: 1670px; min-height: 625px; max-height: 1253px; max-file-size: 500kb"
                  persistent-hint
                  outlined
                  dense
                >
                </v-file-input>
              </v-row>
              <v-row v-if="!showFileField" class="review-file">
                <p class="uppercase">Upload Image File</p>
                <div class="review-file__btns">
                  <v-btn x-small color="primary" dark @click="openPdf(itemDataLocal.image)"> Review File </v-btn>
                  <v-btn x-small color="warning" dark @click="editImageFile()"> Edit File </v-btn>
                </div>
              </v-row>
              <v-card-text v-if="message.image" style="color: #ff3342">
                {{ message.image[0] }}
              </v-card-text>
            </v-col>

            <v-col cols="12" sm="12" v-if="!isAddOns">
              <v-divider class="mb-6"></v-divider>
            </v-col>
            <v-row>
              <v-col cols="12" sm="6" v-if="!isAddOns">
                <v-textarea
                  v-model="itemDataLocal.description_en"
                  :rules="[validators.textareaRule]"
                  outlined
                  dense
                  :counter="250"
                  label="Description (En)"
                  placeholder="Mansaf"
                  hide-details="auto"
                  class="mb-6"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" v-if="!isAddOns">
                <v-textarea
                  v-model="itemDataLocal.description_ar"
                  :rules="[validators.textareaRule]"
                  outlined
                  dense
                  :counter="250"
                  label="Description (Ar)"
                  placeholder="Mansaf"
                  hide-details="auto"
                  class="mb-6"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-col cols="12" sm="12">
              <v-divider class="mb-6"></v-divider>
            </v-col>
            <!-- ############################{{ sizesList }} ######################## -->
            <v-col cols="12" class="d-flex justify-center mt-3" v-if="itemDataLocal.type == 1">
              <v-row>
                <span class="font-weight-medium me-2 p-2">Sizes:</span>
                <v-btn class="mx-2 mb-6" fab light color="indigo" @click="addSize">
                  <v-icon dark>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3" v-if="itemDataLocal.type == 1">
              <div class="d-block w-100">
                <v-row v-for="(item, i) in itemDataLocal.sub_items" :key="item.id">
                  <v-col cols="12" sm="3" class="my-0">
                    <v-checkbox
                      v-model="itemDataLocal.sub_items[i].is_default"
                      class="my-0"
                      :rules="[
                        validators.checkboxValidation(
                          is_chosen_before_is_default,
                          itemDataLocal.sub_items[i].is_default,
                        ),
                      ]"
                      name="default"
                      label="is default"
                      @change="setDefualt(i)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-select
                      v-model="itemDataLocal.sub_items[i].size_id"
                      :items="sizesList"
                      :rules="[validators.required]"
                      item-text="name_en"
                      item-value="id"
                      outlined
                      dense
                      label="Size"
                      @change="setSizeAr(i)"
                    ></v-select>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-if="itemDataLocal.sub_items[i].calculateSizePointsMessage"
                      v-model="itemDataLocal.sub_items[i].price"
                      outlined
                      :rules="[
                        validators.decimal_integer_Validator,
                        itemDataLocal.sub_items[i].calculateSizePointsMessage,
                      ]"
                      dense
                      label="Price (JOD)"
                      @input="calculateSizePoints(i)"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="itemDataLocal.sub_items[i].points"
                      outlined
                      dense
                      disabled
                      label="Price in (Points)"
                      hide-details="auto"
                      type="number"
                      :value="sizePriceInPoints[i]"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="itemDataLocal.sub_items[i].quantity"
                      outlined
                      :rules="
                        itemDataLocal.sub_items[i].quantity == 0 ? ['min value 1'] : [validators.integerValidator]
                      "
                      dense
                      label="Quantity"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-text-field
                      v-model="itemDataLocal.sub_items[i].name_ar"
                      outlined
                      dense
                      :rules="[validators.required]"
                      disabled
                      label="Name ar"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <v-row class="mb-6" v-if="itemDataLocal.sub_items[i].show">
                      <v-file-input
                        class="file"
                        v-model="itemDataLocal.sub_items[i].image"
                        :rules="[
                          validators.required,
                          fileAccept(['jpg', 'jpeg', 'png'], itemDataLocal.sub_items[i].image),
                        ]"
                        :prepend-icon="icons.mdiCamera"
                        accept=".jpg, .jpeg, .png"
                        label="Upload Image (.jpg, .jpeg, .png)"
                        hint="Desired dimensions: min-width: 833px; max-width: 1670px; min-height: 625px; max-height: 1253px; max-file-size: 500kb"
                        persistent-hint
                        outlined
                        dense
                      >
                      </v-file-input>
                    </v-row>
                    <v-row v-if="!itemDataLocal.sub_items[i].show" class="review-file">
                      <p class="uppercase">Upload Image File</p>
                      <div class="review-file__btns">
                        <v-btn x-small color="primary" dark @click="openPdf(itemDataLocal.sub_items[i].image)">
                          Review File
                        </v-btn>
                        <v-btn x-small color="warning" dark @click="editImageFileSizes(i)"> Edit File </v-btn>
                      </div>
                    </v-row>
                    <v-card-text style="color: #ff3342" v-if="message.tablet_path">
                      {{ message.tablet_path[0] }}
                    </v-card-text>
                  </v-col>

                  <v-col align="right" cols="12" sm="3" v-if="is_default_id != item.id && is_default_id != null">
                    <v-btn @click="removeSize(item, 'size')">
                      <v-icon>{{ icons.mdiDelete }}</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-divider class="mb-6"></v-divider>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col cols="12" class="d-flex justify-center mt-3" v-if="itemDataLocal.type == 2">
              <v-row>
                <span class="font-weight-medium me-2 p-2">Components:</span>
                <v-btn class="mx-2 mb-6" fab light color="indigo" @click="addComponent">
                  <v-icon dark>
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
            <v-col cols="12" class="d-flex mt-3" v-if="itemDataLocal.type == 2">
              <div class="d-block w-full">
                <v-row v-for="(item, i) in itemDataLocal.item_components" :key="i">
                  <v-col cols="12" sm="5">
                    <v-text-field v-model="item.name_en" outlined dense label="Name (En)"></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="5">
                    <v-text-field v-model="item.name_ar" outlined dense label="Name (Ar)"></v-text-field>
                  </v-col>
                  <v-col align="right" cols="12" sm="2">
                    <v-btn @click="removeSize(item, 'Components')">
                      <v-icon>{{ icons.mdiDelete }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>

          <v-col cols="12" class="d-flex justify-center mt-3">
            <v-btn color="primary" class="me-3" type="submit" @click.prevent="onSubmit"> submit </v-btn>

            <v-btn
              outlined
              color="secondary"
              @click.prevent="$emit('update:is-bio-dialog-open', false)"
              @click="cancel"
            >
              Discard
            </v-btn>
          </v-col>
        </v-form>
      </v-card-text>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import store from '@/store'
import {
  customeRequired,
  emailValidator,
  checkboxValidation,
  decimal_integer_Validator,
  fileRequired,
  imageValidator,
  integerValidator,
  required,
  textareaRule,
} from '@core/utils/validation'
import { mdiDelete, mdiPlus } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'
import Swal from 'sweetalert2'
import { isString } from '@vueuse/shared'
import _ from 'lodash'
import useItemsList from '../../items-list/useItemsList'

export default {
  emits: ['refreshData'],
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    itemData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { fetchSizes } = useItemsList()
    const isBillingAddress = ref(true)
    const timer = ref(null)
    const calculatePointsMessage = ref(true)
    const message = ref('')
    const isLoading = ref(false)
    const showFileField = ref(false)
    const showFileFieldSizes = ref([null, null])
    const itemDataLocal = ref({})
    const categoryChosen = ref('')
    const subCategories = ref([])
    const isAddOns = ref(false)
    const is_chosen_before_is_default = ref(false)
    itemDataLocal.value = JSON.parse(JSON.stringify(props.itemData))
    const valid = ref(false)
    const form = ref(null)
    const chefs = ref([])
    const addOns = ref([])
    const sizes_id = ref([])
    const selectedAddone = ref([])
    const deleteSubItemArr = ref([])
    const deleteComponentsArr = ref([])
    const sizesList = ref([])
    const is_default_id = ref(null)
    const sizePriceInPoints = ref(0)
    const statusOptions = [
      { title: 'Pending', value: 'pending' },
      { title: 'Active', value: 'active' },
      { title: 'Inactive', value: 'inactive' },
    ]

    const validate = () => {
      form.value.validate()
    }

    const setDefualt = i => {
      itemDataLocal.value.sub_items[i].is_default === true
        ? (itemDataLocal.value.sub_items[i].is_default = 1)
        : (itemDataLocal.value.sub_items[i].is_default = 0)
      let filteredSize = []
      if (itemDataLocal.value.sub_items.length > 1) {
        filteredSize = itemDataLocal.value.sub_items.filter((item, idx) => idx == i)
        is_chosen_before_is_default.value = filteredSize.every((element, idx) => {
          return element.is_default == 1
        })
        console.log('is_chosen_before_is_default.value', is_chosen_before_is_default.value)
      }
      if (is_chosen_before_is_default.value) {
        itemDataLocal.value.sub_items.forEach((element, idx) => {
          if (i != idx) {
            element.is_default = 0
          }
        })
      }
    }

    const setSizeAr = i => {
      let sizeSelectedID = itemDataLocal.value.sub_items.map((ele, i) => {
        return ele.size_id
      })
      console.log('sizeSelectedID', sizeSelectedID)
      console.log('_.difference(sizes_id.value, sizeSelectedID)', _.difference(sizes_id.value, sizeSelectedID))

      const [selectedSize] = sizesList.value.filter((size, idx) => {
        if (_.difference(sizes_id.value, sizeSelectedID).includes(size.id)) {
          size.disabled = false
        } else {
          size.disabled = true
        }
        return size.id === itemDataLocal.value.sub_items[i].size_id
      })
      console.log('sizes_id.value', sizes_id.value)
      console.log('selectedSize', selectedSize)
      if (selectedSize) {
        itemDataLocal.value.sub_items[i].name_en = selectedSize.name_en
        itemDataLocal.value.sub_items[i].name_ar = selectedSize.name_ar
        itemDataLocal.value.sub_items[i].size_id = selectedSize.id
      } else {
        itemDataLocal.value.sub_items[i].name_ar = null
        itemDataLocal.value.sub_items[i].size_id = null
      }
    }
    const resetForm = () => {
      form.value.reset()
    }

    const resetItemData = () => {
      itemDataLocal.value = JSON.parse(JSON.stringify(itemDataLocal))
      resetForm()
    }

    const addSize = () => {
      const obj = {
        id: null,
        name_en: '',
        name_ar: '',
        price: '',
        points: '',
        size_id: '',
        quantity: '',
        image: null,
        calculateSizePointsMessage: true,
        get_from_others: 0,
        is_default: 0,
        show: true,
      }
      itemDataLocal.value.sub_items.push(obj)
    }
    const addComponent = () => {
      const obj = {
        name_en: '',
        name_ar: '',
      }

      itemDataLocal.value.item_components.push(obj)
    }
    const calculatePoints = () => {
      clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        if (itemDataLocal.value.price <= 0 || !itemDataLocal.value.price || itemDataLocal.value.price > 999) {
          itemDataLocal.value.points = 0
          calculatePointsMessage.value = 'MAX VALUE 999'
        } else {
          calculatePointsMessage.value = true
          await store
            .dispatch('app-items/getPriceInPoints', parseFloat(itemDataLocal.value.price))
            .then(response => {
              itemDataLocal.value.points = response.data.data.price
            })
            .catch(error => console.error(error))
        }
      }, 1000)
    }
    const calculateSizePoints = i => {
      clearTimeout(timer.value)
      timer.value = setTimeout(async () => {
        if (itemDataLocal.value.size?.length > 0) {
          itemDataLocal.value.price = itemDataLocal.value.size.filter(ele => ele.is_default)[0].price
        }
        if (
          itemDataLocal.value.sub_items[i].price <= 0 ||
          !itemDataLocal.value.sub_items[i].price ||
          itemDataLocal.value.sub_items[i].price > 999
        ) {
          itemDataLocal.value.sub_items[i].points = 0
          itemDataLocal.value.sub_items[i].calculateSizePointsMessage = 'MAX VALUE 999'
        } else {
          itemDataLocal.value.sub_items[i].calculateSizePointsMessage = true
          store
            .dispatch('app-items/getPriceInPoints', itemDataLocal.value.sub_items[i].price)
            .then(response => {
              itemDataLocal.value.sub_items[i].points = response.data.data.price
            })
            .catch(error => console.error(error))
        }
      }, 1000)
    }
    const subCatChange = () => {
      if (itemDataLocal.value.category_id !== null) {
        const selectedCat = subCategories.value.filter(item => item.id === itemDataLocal.value.category_id)

        if ((selectedCat[0] && selectedCat[0].add_ones === 1) || selectedCat[0].used_in_all_meals === 1) {
          isAddOns.value = true
        } else {
          isAddOns.value = false

          // Fetch Addons list
          store
            .dispatch('app-items/fetchAddons')
            .then(response => {
              addOns.value = response['add-ons']
            })
            .catch(error => console.error(error))
        }
      }
    }
    const removeSize = (item, type) => {
      if (type == 'size') {
        console.log('sizesList.value', sizesList.value)
        deleteSubItemArr.value.push(item)
        itemDataLocal.value.sub_items = itemDataLocal.value.sub_items.filter(size => {
          if (size.size_id == item.size_id) {
            if (sizesList.value[sizesList.value.findIndex(ele => ele.id == item.size_id)]?.disabled) {
              sizesList.value[sizesList.value.findIndex(ele => ele.id == item.size_id)].disabled = false
            }
          }
          return size.size_id !== item.size_id
        })
        if (itemDataLocal.value.sub_items.length == 0) {
          itemDataLocal.value.price = ''
          itemDataLocal.value.points = ''
        }
      }
      if (type == 'Components') {
        deleteComponentsArr.value.push(item)
        itemDataLocal.value.item_components = itemDataLocal.value.item_components.filter(compo => compo !== item)
      }
    }

    const cancel = () => {
      emit('update:is-bio-dialog-open', false)
      showFileField.value = false
      showFileFieldSizes.value = []
      message.value = ''
      emit('refreshData')
    }
    const fileAccept = (accept, data) => {
      if (data && !isString(data)) {
        if (data.size > 500000) {
          return `MAX SIZE 500KB`
        }
        if (accept.includes(data.name.split('.')[1])) {
          return true
        } else {
          return `Please choose file path (${accept})`
        }
      }
      return true
    }

    const openPdf = file => {
      console.log('file', file)
      window.open(file)
    }
    const editImageFile = () => {
      showFileField.value = true
      itemDataLocal.value.image = null
    }
    const editImageFileSizes = idx => {
      itemDataLocal.value.sub_items[idx].show = true
      itemDataLocal.value.sub_items[idx].image = null
      console.log('itemDataLocal.value.sub_items[idx]', itemDataLocal.value.sub_items[idx])
    }
    const sweetAlert = message => {
      Swal.fire({
        icon: 'warning',
        title: 'Failed',
        text: message,
      })
    }
    // on form submit
    const onSubmit = async () => {
      let errorMsg = ''

      if (itemDataLocal.value.sub_items.length) {
        if (itemDataLocal.value.sub_items.length < 2) {
          sweetAlert('You must add at least two sizes')
          return
        }

        if (itemDataLocal.value.sub_items.every(size => !size.is_default)) {
          sweetAlert('Please Choose The Default Size')
          return
        }
      }

      if (valid.value) {
        isLoading.value = true
        if (itemDataLocal.value.image === props.itemData.image) {
          delete itemDataLocal.value.image
        }

        for (let index = 0; index < itemDataLocal.value.sub_items.length; index++) {
          const element = itemDataLocal.value.sub_items[index]
          if (typeof element.image == 'string') {
            delete element.image
          }
        }

        itemDataLocal.value.add_ones = selectedAddone.value
        await store
          .dispatch('app-items/editItem', itemDataLocal.value)
          .then(() => {
            if (deleteSubItemArr.value.length || deleteComponentsArr.value.length) {
              deleteSubItemArr.value.forEach((ele, idx) => {
                if (ele.size_id) {
                  store
                    .dispatch('app-items/deleteSubItem', ele.id)
                    .then(() => {
                      ele.delErr = false
                    })
                    .catch(error => {
                      console.error(error?.response?.data?.message)
                      ele.delErr = true
                      errorMsg = error.response.data.message
                    })
                }
              })
              deleteComponentsArr.value.forEach((ele, idx) => {
                if (ele.id) {
                  store
                    .dispatch('app-items/deleteComponent', ele.id)
                    .then(() => {
                      ele.delErr = false
                    })
                    .catch(error => {
                      console.error(error?.response?.data?.message)
                      errorMsg = error?.response?.data?.message
                      ele.delErr = true
                    })
                }
              })
            }

            setTimeout(() => {
              isLoading.value = false
              emit('update:is-bio-dialog-open', false)
              message.value = ''
              emit('refreshData')
              showFileField.value = false
              showFileFieldSizes.value = []
              if (
                deleteSubItemArr.value.filter(i => i.delErr).length > 0 ||
                deleteComponentsArr.value.filter(i => i.delErr).length > 0
              ) {
                Swal.fire({
                  icon: 'error',
                  title: 'Failed',
                  text: errorMsg,
                })
              } else {
                Swal.fire('Updated!', 'data has been updated successfully.', 'success')
              }
            }, 2000)
          })
          .catch(error => {
            if (error.response?.data?.message) {
              isLoading.value = false
              console.log(error.response.data)
              console.log('error.response?.data?.message', error.response?.data?.message)
              message.value = error.response.data.message
            }
          })
      } else {
        validate()
      }
      console.log('itemDataLocal.value', itemDataLocal.value)
    }

    watch(
      () => props.isBioDialogOpen,
      async () => {
        itemDataLocal.value = JSON.parse(JSON.stringify(props.itemData))
        console.log('itemDataLocal.valueaaaaaaaaaa', itemDataLocal.value)
        if (itemDataLocal.value.category.add_ones == 1) {
          isAddOns.value = true
        }
        deleteSubItemArr.value = []
        deleteComponentsArr.value = []
        if (!isAddOns.value) {
          await store
            .dispatch('app-items/fetchSubCategories', { parent_id: props.itemData.type, item_id: props.itemData.id })
            .then(response => {
              subCategories.value = response.filteredData
              console.log('subCategories.value', subCategories.value)
            })
            .catch(error => {
              console.error(error)
            })
          await store
            .dispatch('app-items/fetchAddons')
            .then(response => {
              addOns.value = response['add-ons']
            })
            .catch(error => console.error(error))
        }

        selectedAddone.value = itemDataLocal.value.add_ones

        await store
          .dispatch('app-items/fetchChefs')
          .then(response => {
            chefs.value = response.data.data.filteredData
          })
          .catch(error => console.error(error))

        await fetchSizes()
          .then(response => {
            sizesList.value = response
            let sizeSelectedIDs = props.itemData.sub_items.map((e, i) => {
              return e.size_id
            })

            sizes_id.value = sizesList.value.map(ele => {
              return ele.id
            })

            sizesList.value.forEach(size => {
              if (_.difference(sizes_id.value, sizeSelectedIDs).includes(size.id)) {
                size.disabled = false
              } else {
                size.disabled = true
              }
            })

            console.log('sizesList.value', sizesList.value)
            console.log('sizes_id.value', sizes_id.value)
          })
          .catch(error => console.error(error))

        is_default_id.value = 0
        itemDataLocal.value.sub_items.forEach((element, idx) => {
          if (element.is_default) {
            is_default_id.value = element.id
          }
          element.calculateSizePointsMessage = true
        })
      },
    )

    return {
      is_default_id,
      isLoading,
      addComponent,
      setSizeAr,
      is_chosen_before_is_default,
      calculateSizePoints,
      calculatePoints,
      calculatePointsMessage,
      cancel,
      editImageFile,
      openPdf,
      message,
      editImageFileSizes,
      showFileFieldSizes,
      showFileField,
      fileAccept,
      isBillingAddress,
      statusOptions,
      itemDataLocal,
      form,
      valid,
      sizesList,
      sizePriceInPoints,
      categoryChosen,
      resetItemData,
      validate,
      onSubmit,
      addSize,
      calculateSizePoints,
      setDefualt,
      removeSize,
      chefs,
      addOns,
      selectedAddone,
      subCatChange,
      subCategories,
      isAddOns,

      // validation
      validators: {
        textareaRule,
        customeRequired,
        emailValidator,
        checkboxValidation,
        decimal_integer_Validator,
        fileRequired,
        imageValidator,
        integerValidator,
        required,
      },
      icons: {
        mdiPlus,
        mdiDelete,
      },
    }
  },
}
</script>
<style lang="scss" scoped>
.file >>> .v-input__slot {
  cursor: pointer !important;
}

.review-file {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &__btns {
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1.4rem;
  }
}
</style>
