<template>
  <v-row class="item-bio-panel">
    <!-- item profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title class="justify-center flex-column">
          <v-img class="mb-3" :src="itemData.image ? itemData.image : ''"></v-img>
          <span class="mb-2">{{ itemData.name }}</span>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>

          <v-divider></v-divider>

          <v-list class="d-flex flex-wrap">
            <v-col cols="12" sm="6">
              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Category:</span>
                <span class="text--secondary">{{ itemData.is_preorder == 1 ? 'preorder' : 'meal' }}</span>
              </v-list-item>
              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2 text-capitalize">sub category (En):</span>
                <span class="text--secondary">{{ itemData.category.name_en }}</span>
              </v-list-item>
              <v-list-item dense class="px-0 mb-n2 text-capitalize">
                <span class="font-weight-medium me-2">sub category (Ar):</span>
                <span class="text--secondary">{{ itemData.category.name_ar }}</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Item Name (En):</span>
                <span class="text--secondary">{{ itemData.name_en }}</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Item Name (Ar):</span>
                <span class="text--secondary">{{ itemData.name_ar }}</span>
              </v-list-item>

              <v-list-item v-if="itemData.chief_name" dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Chef Name:</span>
                <span class="text--secondary">{{ itemData.chief_name }}</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2" v-if="!itemData.is_preorder">
                <span class="font-weight-medium me-2">Quantity:</span>
                <span class="text--secondary">{{ itemData.quantity }}</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Price:</span>
                <span class="text--secondary">{{ itemData.price }} JOD</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Points:</span>
                <span class="text--secondary">{{ itemData.points }} Pt</span>
              </v-list-item>

              <v-list-item dense class="px-0 mb-n2" v-if="itemData.is_preorder">
                <span class="font-weight-medium me-2">Remaining hours:</span>
                <span class="text--secondary">{{ itemData.remaining_hours }}</span>
              </v-list-item>

              <v-list-item v-if="itemData.description_en" dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Description (En):</span>
                <span class="text--secondary">{{ itemData.description_en }}</span>
              </v-list-item>

              <v-list-item v-if="itemData.description_ar" dense class="px-0 mb-n2">
                <span class="font-weight-medium me-2">Description (Ar):</span>
                <span class="text--secondary">{{ itemData.description_ar }}</span>
              </v-list-item>

              <br />
              <h4 v-if="itemData.add_ones && itemData.add_ones.length > 0">Add ones</h4>
              <v-list-item v-if="itemData.add_ones && itemData.add_ones.length > 0" dense class="px-0 mb-n2">
                <v-row>
                  <v-col v-for="(subItem, index) in itemData.add_ones" :key="index" cols="6">
                    <span class="font-weight-medium me-2">#: {{ index + 1 }}</span>
                    <br />
                    <span class="font-weight-medium me-2">Name (En): {{ subItem.name_en }}</span>
                    <br />
                    <span class="font-weight-medium me-2">Name (Ar): {{ subItem.name_ar }}</span>
                    <br v-if="subItem.description_en" />
                    <span v-if="subItem.description_en" class="font-weight-medium me-2"
                      >description (En): {{ subItem.description_en }}</span
                    >
                    <br v-if="subItem.description_ar" />
                    <span v-if="subItem.description_ar" class="font-weight-medium me-2"
                      >description (Ar): {{ subItem.description_ar }}</span
                    >
                    <br v-if="subItem.quantity" />
                    <span v-if="subItem.quantity" class="font-weight-medium me-2"
                      >quantity: {{ subItem.quantity }}</span
                    >
                    <br v-if="subItem.rate" />
                    <span v-if="subItem.rate" class="font-weight-medium me-2">rate: {{ subItem.rate }}</span>
                    <br />
                    <span class="font-weight-medium me-2">points: {{ subItem.points }} pt</span>
                    <br />
                    <span class="font-weight-medium me-2">price: {{ subItem.price }} JOD</span>
                  </v-col>
                </v-row>
              </v-list-item>
              <br />
            </v-col>

            <v-col cols="12" sm="6">
              <h4 v-if="itemData.sub_items && itemData.sub_items.length > 0">Sizes</h4>
              <v-list-item
                v-if="itemData.sub_items && itemData.sub_items.length > 0"
                dense
                class="px-0 mb-n2 flex-wrap"
              >
                <v-col v-for="(subItem, index) in itemData.sub_items" :key="index" cols="6">
                  <span class="font-weight-medium me-2">Size {{ index + 1 }}:</span>
                  <br />
                  <span class="text--primary">Name (En): &nbsp;</span>
                  <span class="text--secondary">{{ subItem.name_en }}</span>
                  <br />
                  <span class="text--primary">Name (Ar): &nbsp;</span>
                  <span class="text--secondary">{{ subItem.name_ar }}</span>
                  <br />
                  <span class="text--primary">Price: &nbsp;</span>
                  <span class="text--secondary">{{ subItem.price }} JOD</span>
                  <br />
                  <span class="text--primary">Points: &nbsp;</span>
                  <span class="text--secondary">{{ subItem.points }} Pt</span>
                  <br />
                  <span class="text--primary">Quantity: &nbsp;</span>
                  <span class="text--secondary">{{ subItem.quantity }}</span>
                  <br />
                  <span class="text--primary text-capitalize">get from others: &nbsp;</span>
                  <span class="text--secondary">{{ subItem.get_from_others?'YES':'NO' }}</span>
                  <br />
                  <span class="text--primary text-capitalize">is default: &nbsp;</span>
                  <span class="text--secondary">{{ subItem.is_default?'YES':'NO' }}</span>
                  <br />
                  <v-btn x-small color="primary" dark @click="openPdf(subItem.image)"> Review Image </v-btn>
                </v-col>
              </v-list-item>
            </v-col>

            <v-col cols="12" sm="12">
              <h4 v-if="itemData.item_components && itemData.item_components.length > 0">Components</h4>
              <v-list-item
                class="d-flex flex-wrap"
                v-if="itemData.item_components && itemData.item_components.length > 0"
                dense
              >
                <v-col v-for="(components, index) in itemData.item_components" :key="index" cols="6">
                  <span class="font-weight-medium me-2">component {{ index + 1 }}:</span>
                  <br />
                  <span class="text--primary">Name (En): &nbsp;</span>
                  <span class="text--secondary">{{ components.name_en }}</span>
                  <br />
                  <span class="text--primary">Name (Ar): &nbsp;</span>
                  <span class="text--secondary">{{ components.name_ar }}</span>
                  <br />
                </v-col>
              </v-list-item>
            </v-col>
          </v-list>
          <v-card-actions class="justify-center mt-3">
            <v-btn color="primary" class="me-3" @click="isBioDialogOpen = !isBioDialogOpen"> Edit </v-btn>
            <v-btn color="error" outlined @click="removeItem(itemData.id)"> Delete </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>

      <!-- edit profile dialog data -->
      <item-bio-edit :is-bio-dialog-open.sync="isBioDialogOpen" :item-data="itemData" @refreshData="refreshData">
      </item-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import store from '@/store'
import { avatarText, kFormatter } from '@core/utils/filter'
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import Swal from 'sweetalert2'
import ItemBioEdit from './ItemBioEdit.vue'

export default {
  components: {
    ItemBioEdit,
  },
  emits: ['refreshData'],
  props: {
    itemData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    removeItem(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('app-items/deleteItem', id)
            .then(() => {
              Swal.fire('Deleted!', 'entry has been deleted.', 'success')
              this.$router.push({ path: '/apps/items/list' })
            })
            .catch(error => {
              console.error(error?.response?.data?.message)
              Swal.fire({
                icon: 'error',
                title: 'Failed',
                text: error.response.data.message,
              })
            })
        }
      })
    },
  },

  setup(props, { emit }) {
    // const { resolveCompanyStatusVariant, resolveCompanyRoleVariant } = useCompaniesList()

    const isBioDialogOpen = ref(false)

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }
    const refreshData = () => {
      emit('refreshData')
    }
    const openPdf = file => {
      window.open(file)
    }
    return {
      openPdf,
      refreshData,
      avatarText,
      kFormatter,
      resolveCurrentPlanValue,

      isBioDialogOpen,
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
      },
    }
  },
}
</script>
