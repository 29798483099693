<template>
  <div id="item-view">
    <v-row>
      <v-col cols="12">
        <item-bio-panel
          v-if="itemData"
          :item-data="itemData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refreshData="refreshData"
        ></item-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { mdiAccountOutline } from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import itemsStoreModule from '../itemsStoreModule'
import ItemBioPanel from './item-bio-panel/ItemBioPanel.vue'

export default {
  components: {
    ItemBioPanel,
  },
  setup() {
    const ITEMS_APP_STORE_MODULE_NAME = 'app-items'

    // Register module
    if (!store.hasModule(ITEMS_APP_STORE_MODULE_NAME)) {
      store.registerModule(ITEMS_APP_STORE_MODULE_NAME, itemsStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEMS_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEMS_APP_STORE_MODULE_NAME)
    })

    const itemData = ref(null)
    const isPlanUpgradeDialogVisible = ref(false)

    const refreshData = () => {
      store
        .dispatch('app-items/fetchItem', { id: router.currentRoute.params.id })
        .then(response => {
          itemData.value = response.data.data
          itemData.value.sub_items.forEach(element => {
            element.calculateSizePointsMessage = true
            element.show = false
          })
          console.log('itemData', itemData.value)
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            itemData.value = {}
          }
        })
    }

    const tabs = [{ icon: mdiAccountOutline, title: 'Employees' }]
    refreshData()

    return {
      refreshData,
      tabs,
      isPlanUpgradeDialogVisible,
      itemData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
