import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCategories() {
      return new Promise((resolve, reject) => {
        axios
          .get('/categories')
          .then(response => {
            resolve(response.data.data.filteredData)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchSubCategories(ctx, params) {
      return new Promise((resolve, reject) => {
        if (params.item_id) {
          axios
            .get(`/categories?options={"parent_id":"${params.parent_id}","item_id":"${params.item_id}"}`)
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => {
              reject(error)
            })
        } else {
          axios
            .get(`/categories?options={"parent_id":"${params.parent_id}"}`)
            .then(response => {
              resolve(response.data.data)
            })
            .catch(error => {
              reject(error)
            })
        }
      })
    },
    fetchChefs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/chiefs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPriceInPoints(ctx, price) {
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/items/points-by-price',
          data: {
            price,
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    fetchAddons() {
      return new Promise((resolve, reject) => {
        axios
          .get('/items/add-ons')
          .then(response => resolve(response.data.data))
          .catch(error => reject(error))
      })
    },
    fetchSizes() {
      return new Promise((resolve, reject) => {
        axios
          .get('/sizes')
          .then(response => resolve(response.data.data.filteredData))
          .catch(error => reject(error))
      })
    },
    fetchItems(ctx, options) {
      console.log('optionsoptions', options)
      return new Promise((resolve, reject) => {
        axios
          .get('/items', { params: { options } })
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    fetchItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios

          .get(`/items/view/${id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    addItem(ctx, data) {
      const bodyFormData = new FormData()

      bodyFormData.append('name_en', data.name_en)
      bodyFormData.append('name_ar', data.name_ar)
      bodyFormData.append('quantity', data.quantity)
      bodyFormData.append('price', data.price)
      bodyFormData.append('category_id', data.category_id)
      bodyFormData.append('image', data.image)

      data.description_en && bodyFormData.append('description_en', data.description_en)
      data.description_ar && bodyFormData.append('description_ar', data.description_ar)
      data.chief_id && bodyFormData.append('chief_id', data.chief_id)
      data.add_ons.length && bodyFormData.append('add_ons', JSON.stringify(data.add_ons))

      data.size.length &&
        data.size.map((item, i) => {
          bodyFormData.append(`item_sizes[${i}][name_en]`, item.name_en)
          bodyFormData.append(`item_sizes[${i}][name_ar]`, item.name_ar)
          bodyFormData.append(`item_sizes[${i}][quantity]`, item.quantity)
          bodyFormData.append(`item_sizes[${i}][price]`, item.price)
          bodyFormData.append(`item_sizes[${i}][get_from_others]`, 0)
          bodyFormData.append(`item_sizes[${i}][image]`, item.image)
          bodyFormData.append(`item_sizes[${i}][size_id]`, item.size_id)
          bodyFormData.append(`item_sizes[${i}][is_default]`, item.is_default)
        })

      data.item_components.length &&
        data.item_components.map((item, i) => {
          bodyFormData.append(`item_components[${i}][name_en]`, item.name_en)
          bodyFormData.append(`item_components[${i}][name_ar]`, item.name_ar)
        })
      data.remaining_hours && bodyFormData.append('remaining_hours', data.remaining_hours)
      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/items/create',
          data: bodyFormData,
          headers: {
            contentType: 'multipart/form-data',
          },
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    editItem(ctx, data) {
      let addones = []
      const bodyFormData = new FormData()
      bodyFormData.append('item_id', data.id)
      bodyFormData.append('name_en', data.name_en)
      bodyFormData.append('name_ar', data.name_ar)
      bodyFormData.append('quantity', data.quantity)
      bodyFormData.append('price', data.price)
      bodyFormData.append('category_id', data.category_id)
      data.image && bodyFormData.append('image', data.image)

      data.description_en && bodyFormData.append('description_en', data.description_en)
      data.description_ar && bodyFormData.append('description_ar', data.description_ar)
      data.chief_id && bodyFormData.append('chief_id', data.chief_id)
      if (data.add_ones && data.add_ones[0] && typeof data.add_ones[0] == 'object') {
        for (let index = 0; index < data.add_ones.length; index++) {
          const element = data.add_ones[index]
          addones.push(element.id)
        }
        bodyFormData.append('add_ons', JSON.stringify(addones))
      } else {
        bodyFormData.append('add_ons', JSON.stringify(data.add_ones))
      }

      data?.sub_items?.length &&
        data.sub_items.map((item, i) => {
          bodyFormData.append(`item_sizes[${i}][id]`, item.id)
          bodyFormData.append(`item_sizes[${i}][name_en]`, item.name_en)
          bodyFormData.append(`item_sizes[${i}][name_ar]`, item.name_ar)
          bodyFormData.append(`item_sizes[${i}][quantity]`, item.quantity)
          bodyFormData.append(`item_sizes[${i}][price]`, item.price)

          item.image && bodyFormData.append(`item_sizes[${i}][image]`, item.image)
          bodyFormData.append(`item_sizes[${i}][size_id]`, item.size_id)
          bodyFormData.append(`item_sizes[${i}][get_from_others]`, 0)
          bodyFormData.append(`item_sizes[${i}][is_default]`, item.is_default ? 1 : 0)
        })

      data?.item_components?.length &&
        data.item_components.map((item, i) => {
          if (item.id) {
            bodyFormData.append(`item_components[${i}][id]`, item.id)
          }
          bodyFormData.append(`item_components[${i}][name_en]`, item.name_en)
          bodyFormData.append(`item_components[${i}][name_ar]`, item.name_ar)
        })
      data.remaining_hours && bodyFormData.append('remaining_hours', data.remaining_hours)
      console.log('###################{{Data}}######################')
      for (var pair of bodyFormData.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      console.log('###################{{Data}}######################')
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/items/update',
          data: bodyFormData,
        })
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteItem(_ctx, _id) {
      const data = { item_id: _id }

      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/items/delete',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteSubItem(_ctx, _id) {
      const data = { sub_item_id: _id }
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/items/delete-sub-item',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
    deleteComponent(_ctx, _id) {
      const data = { component_id: _id }
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: '/items/delete-component',
          data,
        })
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
